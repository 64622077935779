// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-index-index-js": () => import("./../src/templates/index/index.js" /* webpackChunkName: "component---src-templates-index-index-js" */),
  "component---src-templates-index-it-index-it-js": () => import("./../src/templates/index-it/index-it.js" /* webpackChunkName: "component---src-templates-index-it-index-it-js" */),
  "component---src-templates-blog-post-blog-post-js": () => import("./../src/templates/blog-post/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-blog-post-js" */),
  "component---src-templates-blog-post-amp-blog-post-amp-js": () => import("./../src/templates/blog-post-amp/blog-post.amp.js" /* webpackChunkName: "component---src-templates-blog-post-amp-blog-post-amp-js" */),
  "component---src-templates-tags-tags-js": () => import("./../src/templates/tags/tags.js" /* webpackChunkName: "component---src-templates-tags-tags-js" */),
  "component---src-templates-tags-it-tags-it-js": () => import("./../src/templates/tags-it/tags-it.js" /* webpackChunkName: "component---src-templates-tags-it-tags-it-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-it-404-js": () => import("./../src/pages/it/404.js" /* webpackChunkName: "component---src-pages-it-404-js" */),
  "component---src-pages-it-live-js": () => import("./../src/pages/it/live.js" /* webpackChunkName: "component---src-pages-it-live-js" */),
  "component---src-pages-it-tags-js": () => import("./../src/pages/it/tags.js" /* webpackChunkName: "component---src-pages-it-tags-js" */),
  "component---src-pages-live-js": () => import("./../src/pages/live.js" /* webpackChunkName: "component---src-pages-live-js" */),
  "component---src-pages-tags-js": () => import("./../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */)
}

